import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {

    return (
        <Layout>
            <SEO title="About" />
            <div className="about-hero">
                <div className="content-wrapper">
                    <div className="about-hero__content">
                        <div className="d-flex align-items-start">
                            <StaticImage className="mr-4 flex-shrink-0" width={120} src="../images/about/debbie-waters.png" alt='Debbie Waters' />
                            <div>
                                <p className="lead">"I have worked in the conflict transformation and restorative field for over 30years  and <b className="bold">Restorativ</b> respresents a seismic shift in our ability to reach a larger market"</p>
                                <p className=""><b className="bold">Debbie Watters OBE</b></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="content-wrapper">
                <div className="page-intro pb-4">
                    <h1 className="h2 text-left">The best solutions are found where industry experts and technologists come together.</h1>
                    <p className='lead mb-0'>Restorativ has combined academic knowledge with technology to create a tool that brings restorative justice into the 21st century. Restorativ allows its users to move away from punitive approaches and towards rebuilding relationships and communities</p>
                </div>
                <div className="container-fluid">
                    <h3 className="text-center mb-4">Restorativ Team</h3>
                    <div className="row team">
                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/anamika.png" alt='Anamika Twyman Ghoshal' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Dr Anamika Twyman Ghoshal</h4>
                                    <p>Anamika is a Senior Lecturer at Brunel University London and has a Ph.D. in Criminology and Justice Policy from Northeastern University, USA. Her area of research is on power and harm, restorative justice and practices, and decolonising knowledge production.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/chris.png" alt='Chris Twyman' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Chris Twyman</h4>
                                    <p>Chris is a technology marketer who has worked in both large software businesses and on the founding team of tech start-ups. He holds an MBA and a Degree in Quantity Surveying.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/jon.png" alt='Jon Edwards' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Jon Edwards</h4>
                                    <p>Jon is an award winning User Experience (UX) expert who has developed mobile and desktop apps for education, talent management, and financial industries.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/raul-radu.jpg" alt='Raul Radu' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Raul Radu</h4>
                                    <p>Raul is a back-end developer and the architect of the Restorativ App.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/loreley-petroiu.jpg" alt='Loreley Petroiu' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Loreley Petroiu</h4>
                                    <p>Loreley provides our interactive capability on the apps and website. She is our lead front-end developer.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/ian-garland.jpg" alt='Ian Garland' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Ian Garland</h4>
                                    <p>Ian is a UX strategist and award-winning designer of branding approaches, websites, desktop applications and mobile apps.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/kalyani.png" alt='Kalyani Twyman - PHD Candidate' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Kalyani Twyman - PHD Candidate</h4>
                                    <p>Kalyani is researching Evolutionary Biology at the University of St. Andrews. She is responsible for Restorativ's positioning to our younger users.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/pablo.jpg" alt='Pablo Romero - PHD' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Dr Pablo Romero Seseña</h4>
                                    <p>With a background in Criminology and Human Rights, Pablo holds a PhD from Universitat Oberta de Catalunya, and is an active researcher in the consolidated research group "Empirical and Applied Victimology" (VICRIM), as well as an assistant professor in the Criminology Degree program at the same university. His research has focused on the impact and implications of using technology in the Restorative Justice field, and other victimological issues such as the response of the criminal justice system to victims of sexual violence. He has been a visiting researcher at the Catholic University of Leuven (KU Leuven), Belgium, and has published several papers in impactful journals nationally and internationally.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className="text-center mb-4">RJC Product Steering Group</h3>

                    <div className="row team">
                        <div className="col-md-6 mb-4">
                            <div className="team__user mb-4">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/chris.png" alt='Chris Twyman' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Chris Twyman</h4>
                                    <p>Chris is a technology marketer who has worked in both large software businesses and on the founding team of tech start-ups. He holds an MBA and a Degree in Quantity Surveying.</p>
                                </div>
                            </div>
                            <div className="team__user mb-4">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/jim-simon.png" alt='Jim Simon' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Jim Simon</h4>
                                    <p>Jim assumed the position of the RJC's chief executive officer in April 2019, after serving as a standards assessor for the charity since 2014. Throughout his professional career, he has been a staunch proponent of restorative justice and has led the implementation of restorative practices in various sectors including education, criminal and youth justice. As an integral part of his work, Jim has been the chair of the Advisory Board for the All-Party Parliamentary Group for Restorative Justice for the past four years. In this capacity, he closely collaborates with parliamentarians from England and Northern Ireland to promote awareness and understanding of restorative justice in different policy areas, including criminal justice and community transformation. Additionally, Jim has successfully established the presence of RJC in Northern Ireland and works closely with local community organisations to harness the full potential of restorative justice in addressing complex social issues.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="team__user mb-4">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/debbie-waters.png" alt='Debbie Watters' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Debbie Watters</h4>
                                    <p>Debbie has been involved in the area of justice work, peace building, reconciliation and youth work for the past 30 years. She completed her degree at the Ulster University and has worked as a restorative justice practitioner for the past 25 years including the management of the first restorative justice programme in the USA from 1993-1997. She has been involved with the development of restorative justice initiatives in Northern Ireland since 1997 and played a key role in transferring her learnings from the USA into the Northern Ireland context.</p>
                                </div>
                            </div>
                            <div className="team__user mb-4">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/paul-parker.png" alt='Paul  Parket' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Paul T. Parker</h4>
                                    <p>Paul T. Parker is senior vice president, head of North America HR at OFI. Paul brings over 30 years of HR experience. He has worked in diverse industries as a strategic HR partner and most recently served as CHRO & Global SVP of Safety Health and Sustainability for SunRice Group. Paul has also served as CHRO at Royal Caribbean.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className="text-center mb-4">Advisors</h3>
                    <div className="row team">
                        <div className="col-md-6 mb-4">
                            <div className="team__user mb-3">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/jonathan.png" alt='Dr Jonathan Hobson' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Dr Jonathan Hobson</h4>
                                    <p>Dr Jonathan Hobson is a an Associate Professor of Social Sciences and a Senior Fellow of the Higher Education Academy. He is a political sociologist by training and has worked extensively across the social sciences including in criminology and criminal justice. </p>
                                </div>
                            </div>
                      
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="team__user mb-4">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/jeff.png" alt='Jeff Upton' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Jeff Upton</h4>
                                    <p>Jeff is US Lawyer and a Commercial Strategist. Jeff graduated from Brown University and is also part-time actor.</p>
                                </div>
                            </div>
                            <div className="team__user">
                                <div className="team__user__img">
                                    <StaticImage width={80} src="../images/about/sarah.jpg" alt='Sarah Smith Robbins' />
                                </div>
                                <div className="team__user__content">
                                    <h4>Dr. Sarah Smith Robbins</h4>
                                    <p>Sarah is a faculty member at the Kelley School of Business at Indiana University where her focus is learning technologies.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-rj">
                <div className="container">
                    <StaticImage className="about-rj_logo" src="../images/about/rjc-white.png" alt='' />
                    <p className="mb-4 lead">Restorativ and the RJC partner to provide the next generation of Restorative Solutions</p>
                </div>
                <StaticImage className="about-rj_img" src="../images/about/hero.jpg" alt='' />
                <div className="container about-rj_text">
                    <a className="btn mt-4" target="blank" href="https://restorativejustice.org.uk/">restorativejustice.org.uk</a>
                    <p className="pt-4 mb-4">The Restorative Justice Council (RJC) is the independent third sector membership body for the field of restorative practice. It provides quality assurance and a national voice advocating the widespread use of all forms of restorative practice, including restorative justice. The RJC’s vision is of a society where high quality restorative practice is available to all.</p>
                    <p>The RJC's role is to set and champion clear standards for restorative practice. It ensures quality and supports those in the field to build on their capacity and accessibility. At the same time, the RJC raises public awareness and confidence in restorative processes. The ultimate aim of the RJC is to drive take-up and to enable safe, high quality restorative practice to develop and thrive.</p>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="container-fluid">
                    <div id="links">
                        <h2 className='text-center mb-3'>Links we love</h2>
                        <div className="row about-links">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://www.iirp.edu/pdf/RJ_full_report.pdf">
                                    <div className="card-body">
                                        <div className="img img-xs">
                                            <StaticImage width={100} src="../images/about/logos/smith.png" alt='The Smith Institute' />
                                        </div>
                                        <h5>The Smith Institute</h5>
                                        <p>Restorative Justice: the evidence</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://www.researchgate.net/profile/Lawrence-Sherman/publication/228799673_Repairing_the_harm_Victims_and_restorative_justice/links/0c96053a544b4c11d3000000/Repairing-the-harm-Victims-and-restorative-justice.pdf">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/researchgate.png" alt='ResearchGate' />
                                        </div>
                                        <h5>ResearchGate</h5>
                                        <p>Repairing the Harm: Victims and Restorative Justice</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://restorativejustice.org.uk/resources/evidence-supporting-use-restorative-justice">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/rjcouncil.png" alt='Restorative Justice Council' />
                                        </div>
                                        <h5>Restorative Justice Council</h5>
                                        <p>Evidence supporting the use of restorative justice</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="http://restorativejustice.org">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/rjcouncil.png" alt='Restorative Justice Council' />
                                        </div>
                                        <h5>Restorative Justice Council</h5>
                                        <p>A program of prison fellowship internal</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://www.weareteachers.com/restorative-justice/">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/weareteachers.png" alt='We Are Teachers' />
                                        </div>
                                        <h5>We Are Teachers</h5>
                                        <p>What Teachers Need to Know About Restorative Justice</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://restorativejustice.org.uk/restorative-practice-workplace">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/rjcouncil.png" alt='Restorative Justice Council' />
                                        </div>
                                        <h5>Restorative Justice Council</h5>
                                        <p>Restorative practice in the workplace</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://read.dukeupress.edu/tikkun/article-abstract/27/1/25/30384/Restorative-Justice-Some-Facts-and-History?redirectedFrom=fulltext">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/tikkun.png" alt='Tikkun' />
                                        </div>
                                        <h5>Tikkun</h5>
                                        <p>Restorative Justice: some facts and history</p>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://antirecidivism.org">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/arc.png" alt='Arc' />
                                        </div>
                                        <h5>The Anti-Recidivism Coalition</h5>
                                    </div>
                                </a>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://restorativejustice.org.uk">
                                    <div className="card-body">
                                        <div className="img">
                                            <StaticImage width={170} src="../images/about/logos/tikkun.png" alt='Tikkun' />
                                        </div>
                                        <h5>Restorative Justice Council</h5>
                                        <p>Restorative Justice</p>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <a className="card about-links__item" href="https://en.wikipedia.org/wiki/Restorative_justice">
                                    <div className="card-body">
                                        <div className="img img-xs">
                                            <StaticImage width={170} src="../images/about/logos/wikipedia.png" alt='Wikipedia' />
                                        </div>
                                        <h5>Wikipedia</h5>
                                        <p>Restorative Justice</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About
